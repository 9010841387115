import { Box, Divider, Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BookingFilterData } from "../../../models/bookingData";
import { mapToParticipationStatusesString } from "../../../models/enums/participationStatus.enum";
import { BookingsSortBy } from "../../../models/enums/sortBy.enum";
import { PAGE_SIZE } from "../../../models/pagination";
import { AppState } from "../../../redux";
import {
  getAllMyEvents,
  getAllMyEventsForPrint,
  setPageCount,
} from "../../../redux/bookings/actions";
import { BookingFilterService } from "../../../services/filters/bookingFilterService";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import NoEventsFound from "../../../components/core/events/shared/NoEventsFound";
import BookingFilterForm from "../../../components/core/events/my-events/BookingFilterForm";
import { MyEventsPrintList } from "../../../components/core/events/my-events/MyEventsPrintList";
import { useReactToPrint } from "react-to-print";
import SvgIconBase from "../../../assets/icons/custom-svg-components/SvgIconBase";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../models/enums/trainingType.enum";
import MyEventTitleContent, {
  SUB_EVENTS,
} from "../../../components/core/events/my-events/MyEventTitleContent";
import { v4 as uuidv4 } from "uuid";
import {
  mapToEventTypeString,
  mapToEventTypeStringTranslationKey,
} from "../../../models/enums/eventType.enum";
import { getELearningDates } from "../../../models/elearning";
import { useTranslation } from "react-i18next";

export const UserEventsPage: React.FC = () => {
  const { t } = useTranslation(["common", "enums", "events", "snackbars"]);

  const dispatch = useDispatch();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const eventList = useSelector((state: AppState) => state.booking.myEventList);
  const eventListPrint = useSelector(
    (state: AppState) => state.booking.myEventListPrint
  );
  const userData = useSelector((state: AppState) => state.user.currentUser);
  const booking = useSelector((state: AppState) => state.booking);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const getInitialFilterData = React.useCallback(() => {
    let initialFilterData: BookingFilterData = {
      event__training_type: "",
      status: [],
      sorting: t(BookingsSortBy.EndAscending, { ns: "enums" }),
    };

    // ! persist the filter state after page change and set as initial filter values
    const filterService = new BookingFilterService(
      booking.currentPage,
      initialFilterData
    );
    initialFilterData = filterService.parseFiltersFromUrl();

    return initialFilterData;
  }, [t, booking.currentPage]);

  const loadData = React.useCallback(() => {
    if (!booking.isLoading && !booking.myEventListLoaded) {
      dispatch(getAllMyEvents(booking.currentPage, getInitialFilterData()));
      dispatch(getAllMyEventsForPrint(getInitialFilterData()));
    }
  }, [
    booking.currentPage,
    dispatch,
    getInitialFilterData,
    booking.isLoading,
    booking.myEventListLoaded,
  ]);

  useEffect(loadData, [loadData]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <HeadingLumos>{t("myBookings.title", { ns: "events" })}</HeadingLumos>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={handlePrint}
            aria-label="print my events"
            style={{
              float: "right",
              cursor: "pointer",
            }}
            color="inherit"
          >
            <SvgIconBase
              style={{ stroke: "var(--text-color)", fontSize: "1.5625" }}
              component={Print}
            />
          </IconButton>
        </Grid>
      </Grid>
      {/*Printable version of myevent list */}
      <Box style={{ display: "none" }}>
        <MyEventsPrintList
          ref={componentRef}
          eventList={eventListPrint}
          userData={userData}
        />
      </Box>
      {/*End of Printable version of myevent list */}
      <Formik
        // eslint-disable-next-line
        onSubmit={(data: BookingFilterData) => {}}
        enableReinitialize
        initialValues={getInitialFilterData()}
      >
        {() => (
          <Form>
            <BookingFilterForm page={booking.currentPage} />
          </Form>
        )}
      </Formik>

      <Grid container spacing={0} className="myevents-list-header">
        <Hidden mdDown>
          <Grid item xs={1}>
            {t("course.courseID", { ns: "common" })}
          </Grid>
        </Hidden>
        <Grid item xs={10} sm={7} md={4} lg={3}>
          <Hidden mdDown>{t("title", { ns: "events" })}</Hidden>
          <Hidden lgUp>{t("eventType.event", { ns: "events" })}</Hidden>
        </Grid>
        <Hidden smDown>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {t("myBookings.eventDate", { ns: "events" })}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {t("eLearning.periodDetail", { ns: "events" })}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {t("eventType.title", { ns: "events" })}
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {t("status.titleSimple", { ns: "events" })}
          </Grid>
        </Hidden>
      </Grid>
      {booking.isLoading ? (
        <Loader />
      ) : eventList.count === 0 ? (
        <NoEventsFound />
      ) : (
        eventList.results.map((bookingItems) => {
          const { begin, end } = getELearningDates(bookingItems);
          const eLearningDates = [
            TrainingType.ELearning,
            TrainingType.BlendedLearning,
          ].includes(bookingItems.event.training_type)
            ? begin.toLocaleDateString() + "-" + end.toLocaleDateString()
            : "-";
          const when =
            bookingItems.event.training_type === TrainingType.ELearning
              ? "-"
              : new Date(bookingItems.event.begin).toLocaleDateString();
          const trainingType = [
            TrainingType.ELearning,
            TrainingType.BlendedLearning,
          ].includes(bookingItems.event.training_type)
            ? `${mapToTrainingTypeString(bookingItems.event.training_type)}`
            : "";
          const eventType = t(
            mapToEventTypeStringTranslationKey(
              mapToEventTypeString(bookingItems.event.event_type)
            ),
            { ns: "events" }
          );
          const location =
            bookingItems.event.location.length > 0 &&
            bookingItems.event.location !==
              mapToEventTypeString(bookingItems.event.event_type)
              ? ` (${bookingItems.event.location})`
              : "";
          const where =
            `${eventType}${location}`.length > 0 ? ` (${eventType}${location})` : "";
          // remove double-parenthesis
          let whatWhere = (trainingType + where).replaceAll("))", ")");
          // remove first parenthesis if no parenthesis are needed at all
          if (whatWhere.charAt(1) === "(") whatWhere = whatWhere.replace("(", "");
          // replace last opening parenthesis with dash separating event-type and location
          if ((whatWhere.match(/\(/g) || []).length > 1)
            // eslint-disable-next-line
            whatWhere = whatWhere.replace(/\(([^\()]+)\)$/, " - $1)");
          // remove the closing parenthesis which is missing an opening parenthesis
          if (
            (whatWhere.match(/\)/g) || []).length === 1 &&
            (whatWhere.match(/\(/g) || []).length === 0
          )
            whatWhere = whatWhere.replace(")", "");
          return (
            <React.Fragment key={uuidv4()}>
              <Grid container spacing={0} className="myevents-list-item">
                <Hidden mdDown>
                  <Grid item xs={1}>
                    # {bookingItems.event.public_id}
                  </Grid>
                </Hidden>
                <Grid item xs={10} sm={7} md={4} lg={3}>
                  <Hidden lgUp>
                    # {bookingItems.event.public_id}
                    <br />
                  </Hidden>
                  <Hidden smDown>
                    <MyEventTitleContent
                      text={bookingItems.event.title}
                      event={bookingItems}
                    />
                  </Hidden>
                  <Hidden mdUp>
                    <b>Titel:</b>{" "}
                    <MyEventTitleContent
                      key={uuidv4()}
                      text={bookingItems.event.title}
                      event={bookingItems}
                    />
                  </Hidden>
                  {bookingItems.event.training_type === TrainingType.BlendedLearning && (
                    <Box component="div" style={{ display: "block" }}>
                      {SUB_EVENTS.map((subEvent) => (
                        <MyEventTitleContent
                          key={uuidv4()}
                          text={subEvent}
                          event={bookingItems}
                        />
                      ))}
                    </Box>
                  )}
                  <Hidden mdUp>
                    {when.length > 1 && (
                      <div>
                        <b>{t("myBookings.eventDate", { ns: "events" })}: </b> {when}
                      </div>
                    )}
                    {eLearningDates.length > 1 && (
                      <div>
                        <b>{t("eLearning.periodDetail", { ns: "events" })}: </b>{" "}
                        {eLearningDates}
                        {bookingItems.reactivated ? (
                          <>
                            <br />
                            <Typography variant="caption">
                              <strong>
                                (
                                {t("eLearning.periodProlonged", {
                                  ns: "events",
                                })}{" "}
                                {new Date(bookingItems.overall_end).toLocaleDateString()}
                                )
                              </strong>
                            </Typography>
                          </>
                        ) : null}
                      </div>
                    )}
                    <div>
                      <b> {t("eventType.title", { ns: "events" })}:</b> {whatWhere}
                    </div>
                  </Hidden>
                  <Hidden smUp>
                    <br /> <b> {t("status.titleSimple", { ns: "events" })}:</b>{" "}
                    {mapToParticipationStatusesString(bookingItems.status)}
                  </Hidden>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {when}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {eLearningDates}
                    {bookingItems.reactivated ? (
                      <>
                        <br />
                        <Typography variant="caption">
                          <strong>
                            (
                            {t("eLearning.periodProlonged", {
                              ns: "events",
                            })}{" "}
                            {new Date(bookingItems.overall_end).toLocaleDateString()})
                          </strong>
                        </Typography>
                      </>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {whatWhere}
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {mapToParticipationStatusesString(bookingItems.status)}
                  </Grid>
                </Hidden>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })
      )}

      <Grid container style={{ marginTop: ".625rem" }}>
        <Box component="div" style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(eventList.count / PAGE_SIZE)}
            page={booking.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};
