import { Grid, Typography } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { UserType } from "../../../models/enums/userType.enum";
import { EventFilterData } from "../../../models/eventData";
import { AppState } from "../../../redux";
import { getAllEvents, setFilterData } from "../../../redux/events/actions";
import { getLinkedAccounts } from "../../../redux/linkAccount/actions";
import usePermission from "../../../services/usePermissions";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import CreateTrainingForm from "../../../components/core/events/list/CreateTrainingForm";
import EventFilterForm from "../../../components/core/events/list/EventFilterForm";
import "./EventListPage.scss";
import PageItems from "../../../components/core/events/list/PageItems";
import { useTranslation } from "react-i18next";

export const EventListPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["events", "customer", "snackbars"]);

  const eventState = useSelector((state: AppState) => state.event);
  const auth = useSelector((state: AppState) => state.auth);
  const linkAccount = useSelector((state: AppState) => state.linkAccount);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const eventFilterData = eventState.filterData;

  const { isStaff } = usePermission(currentUser?.user_type);

  const loadMore = useCallback(
    (filters: EventFilterData) => {
      // don't load more elements if there are elements already loading and there are no more sites to load
      if (eventState.hasMoreItemsToLoad && !eventState.isLoading) {
        dispatch(getAllEvents(eventState.currentPage + 1, filters));
      }
    },
    [
      dispatch,
      eventState.currentPage,
      eventState.hasMoreItemsToLoad,
      eventState.isLoading,
    ]
  );

  useEffect(() => {
    if (currentUser) {
      if (
        !linkAccount.linkAccountListLoaded &&
        currentUser?.user_type === UserType.Participant
      ) {
        dispatch(getLinkedAccounts());
      }
    }
  }, [linkAccount.linkAccountListLoaded, dispatch, currentUser]);

  useEffect(() => {
    if (
      !eventState.isLoading &&
      eventState.currentPage === 0 &&
      eventState.hasMoreItemsToLoad
    ) {
      loadMore(eventState.filterData);
    }
  }, [
    eventState.isLoading,
    eventState.filterData,
    eventState.currentPage,
    eventState.hasMoreItemsToLoad,
    loadMore,
  ]);

  const ChangeEventFilterForm = () => {
    // ! is needed to make sure every time the values of the form change the filter data is updated without interfering with the formik render process
    const { values, initialValues } = useFormikContext<EventFilterData>();
    useEffect(() => {
      if (
        JSON.stringify(values) !== JSON.stringify(eventState.filterData) &&
        JSON.stringify(values) !== JSON.stringify(initialValues) &&
        !eventState.isLoading
      ) {
        dispatch(setFilterData(values));
      }
    }, [initialValues, values]);

    return null;
  };

  return (
    <>
      <HeadingLumos>
        <strong>{t("title", { ns: "customer" })}</strong>
      </HeadingLumos>
      <Grid container spacing={4} style={{ marginBottom: "0rem" }}>
        <Grid item sm={10}>
          <Typography>
            {" "}
            {t("welcomeText.part1", { ns: "customer" })}
            <br />
            <br />
            {t("welcomeText.part2", { ns: "customer" })}
            <br />
            <br />
            <br />
            {t("welcomeText.part3", { ns: "customer" })}
            <br />{" "}
          </Typography>
        </Grid>
        {/* If not logged in and staff member events can't be added */}
        {auth.loggedIn && isStaff() && <CreateTrainingForm />}
      </Grid>
      {/* TODO: Exclude completely only if customer === 'Wetterau' */}
      {/*{(currentUser?.user_type === UserType.Participant || !currentUser) && (*/}
      {/*  <LearningEventsInfoLink />*/}
      {/*)}*/}
      <Formik onSubmit={() => {}} enableReinitialize initialValues={eventFilterData}>
        {({ values }) => {
          return (
            <>
              <ChangeEventFilterForm />
              <Form style={{ marginBottom: "1rem" }}>
                <EventFilterForm />
              </Form>
              <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                  loadMore(values);
                }}
                initialLoad={false}
                hasMore={eventState.hasMoreItemsToLoad && !eventState.isLoading}
                loader={
                  <React.Fragment key={0}>
                    {/* // ! Use key directly in loader to remove warning in console */}
                    <Loader />
                  </React.Fragment>
                }
              >
                {/* Render every element in every page */}
                {eventState.eventList.length === 0 ||
                (eventState.currentPage === 0 && eventState.isLoading) ? (
                  eventState.isLoading && <Loader />
                ) : (
                  <>
                    <PageItems pages={eventState.eventList} />
                    {eventState.isLoading && <Loader />}
                  </>
                )}
              </InfiniteScroll>
            </>
          );
        }}
      </Formik>
    </>
  );
};
